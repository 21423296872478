<template>
  <div>
    <div v-if="user" ref="report">
      <div class="report-view bg-white" id="printableArea">
        <div class="header report-header">
          <h2>REPORT PESERTA</h2>
          <img
            :src="
              pel.img_location
                ? elearningUrl + pel.img_location
                : `${elearningUrl}/dist/img/placeholder.jpg`
            "
            alt="logo report"
          />
        </div>
        <div class="body">
          <div>
            <h3>IDENTITAS PESERTA</h3>
            <div>
              <b>Nama</b>
              <div>{{ user.name }}</div>
            </div>
            <div>
              <b>Email</b>
              <div>{{ user.email }}</div>
            </div>
            <div>
              <b>Nama Instansi</b>
              <div>
                {{ user.nama_instansi ? user.nama_instansi : `Belum diisi` }}
              </div>
            </div>
          </div>
          <div>
            <h3>PELATIHAN</h3>
            <div>
              <b>Nama Pelatihan</b>
              <div>{{ pel.name }}</div>
            </div>
            <div>
              <b>Deskripsi</b>
              <div v-html="pel.desc"></div>
            </div>
          </div>
          <div>
            <h3>TUGAS</h3>
            <table class="tb-color">
              <thead>
                <th>Nama Penugasan</th>
                <th>Tanggal Upload</th>
                <th>Nilai</th>
              </thead>
              <tbody class="report-tugas">
                <tr v-for="(item, index) in tugas" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>
                    {{
                      answers[index]
                        ? formatDate(answers[index])
                        : `Belum Mengerjakan`
                    }}
                  </td>
                  <td>{{ score[index] ? score[index] : `Belum Dinilai` }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h3>TEST</h3>
            <table class="tb-color">
              <tr>
                <th>Status Test</th>
                <th>Jenis Test</th>
                <th>Nilai</th>
              </tr>
              <tbody class="report-test">
                <tr>
                  <td>{{ test.active_pre ? `Aktif` : `Non-aktif` }}</td>
                  <td>Pre-Test</td>
                  <td>
                    {{
                      testResult
                        ? testResult.pre != null
                          ? testResult.pre
                          : `Belum Mengerjakan`
                        : `Belum Mengerjakan`
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ test.active_post ? `Aktif` : `Non-aktif` }}</td>
                  <td>Post-Test</td>
                  <td>
                    {{
                      testResult
                        ? testResult.post != null
                          ? testResult.post
                          : `Belum Mengerjakan`
                        : `Belum Mengerjakan`
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="report-footer">
          <p class="text-muted">Laporan {{ user.name }} | {{ dateNow() }}</p>
        </div>
      </div>
    </div>

    <button
      v-if="user"
      @click="print"
      class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
      style="border-radius: 100px"
      v-ripple
    >
      Download
    </button>
  </div>
</template>

<style lang="scss" scoped>
/* report view */
.report-view {
  position: relative;
}
.report-view .header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 3px solid #00b0f0;
}
.report-view h2 {
  color: #00b0f0;
  font-weight: bold;
  align-self: flex-end;
}
.report-view img {
  height: 50px;
}
.report-view .body {
  padding-top: 20px;
  margin-left: 20px;
}
.report-view .body > div,
.report-view .body > span {
  margin-bottom: 30px;
}
.report-view .body > div > div,
.report-detail > div > div {
  display: flex;
}
.report-view h3 {
  color: #00b0f0;
  font-weight: normal;
  font-size: 20px;
  font-weight: bold;
}
.report-view .body b {
  width: 25%;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
}
.report-view .body b::after {
  content: ":";
  position: absolute;
  right: 10px;
}
.report-view .body p {
  max-width: 50%;
}

.tb-color {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.tb-color td,
.tb-color th {
  border: 1px solid #deeaf6;
  padding: 8px;
}

.tb-color tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tb-color tr:hover {
  background-color: #deeaf6;
}

.tb-color th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #5b9ad5;
  color: white;
}
</style>

<script>
import Moment from "moment";

export default {
  name: "peningkatan_kapasitas_detail",
  props: [
    "ref",
    "id",
    "user",
    "tugas",
    "pel",
    "score",
    "answers",
    "test",
    "testResult"
  ],
  components: {},
  data() {
    return {
      elearningUrl: process.env.VUE_APP_ELEARNING_URL
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    setBantuActiveTab(name, index) {
      this.activeTab = name;
      this.activeTabIndex = index;
    },
    setActiveTabIndex(index) {
      this.activeTabIndex = index;
    },
    formatDate(date) {
      return Moment(date, "Y-M-D H:i:s").format("D MMM Y");
    },
    dateNow() {
      return Moment().format("D MMM YYYY");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    print() {
      // Get all stylesheets HTML
      let stylesHtml = "";
      // for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
      //   stylesHtml += node.outerHTML;
      // }

      stylesHtml = `
        .report-view {
          position: relative;
        }
        .report-view .header {
          position: relative;
          display: flex;
          justify-content: space-between;
          padding-bottom: 20px;
          border-bottom: 3px solid #00b0f0;
        }
        .report-view h2 {
          color: #00b0f0;
          font-weight: bold;
          align-self: flex-end;
        }
        .report-view img {
          height: 50px;
        }
        .report-view .body {
          padding-top: 20px;
          margin-left: 20px;
        }
        .report-view .body > div,
        .report-view .body > span {
          margin-bottom: 30px;
        }
        .report-view .body > div > div,
        .report-detail > div > div {
          display: flex;
        }
        .report-view h3 {
          color: #00b0f0;
          font-weight: normal;
          font-size: 20px;
          font-weight: bold;
        }
        .report-view .body b {
          width: 25%;
          position: relative;
          padding-right: 10px;
          padding-left: 10px;
        }
        .report-view .body b::after {
          content: ":";
          position: absolute;
          right: 10px;
        }
        .report-view .body p {
          max-width: 60%;
          padding: 0;
          margin: 0;
        }

        .tb-color {
          font-family: Arial, Helvetica, sans-serif;
          border-collapse: collapse;
          width: 100%;
        }

        .tb-color td,
        .tb-color th {
          border: 1px solid #deeaf6;
          padding: 8px;
        }

        .tb-color tr:nth-child(even) {
          background-color: #f2f2f2;
        }

        .tb-color tr:hover {
          background-color: #deeaf6;
        }

        .tb-color th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: #5b9ad5;
          color: white;
        }
      `;

      // // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          
          <style>
            ${stylesHtml}
          </style>
        </head>
        <body>
          ${this.$refs.report.innerHTML}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    }
  }
};
</script>
