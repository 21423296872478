<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <div class="d-flex align-items-center">
        <div
          v-if="!trashActive"
          @click="
            trashActive = true;
            data = [];
            loading = true;
            getData();
          "
          class="c-custom-modal-btn py-2 mr-4"
          v-ripple
        >
          <img
            :src="`${baseAppPath}/media/delete-green-icon.svg`"
            width="10px"
            class="mb-1"
          />
          <span>Kotak Sampah</span>
        </div>

        <div
          v-if="trashActive"
          @click="
            trashActive = false;
            data = [];
            loading = true;
            getData();
          "
          class="c-custom-modal-btn py-2 mr-4"
          v-ripple
        >
          <span>&#60; Kembali ke Daftar</span>
        </div>

        <div class="per-page-wrapper">
          <div>Show:</div>
          <div style="width: 1px; background: #D7D4D3"></div>
          <div>
            <b-dropdown size="sm" variant="link" right no-flip>
              <template v-slot:button-content>
                <div style="margin-left: -15px">{{ perPage }}</div>
              </template>

              <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
              <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
              <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
              <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div>
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="data"
        :sort-by.sync="columnName"
        :sort-desc.sync="isDescending"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div class="c-max-lines-1">
            {{ item.name }}
          </div>
        </template>

        <template v-slot:[`item.desc`]="{ item }">
          <div class="c-max-lines-1">
            {{ strippedContent(item.desc) }}
          </div>
        </template>

        <template v-slot:[`item.file_location`]="{ item }">
          <div class="text-truncate" style="width: 200px">
            {{ item.file_location }}
          </div>
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <div>{{ item.active == 1 ? "Aktif" : "Nonaktif" }}</div>
        </template>

        <template v-slot:[`item.hasil`]="{ item }">
          <div
            :key="'btn-nilai-' + item.id"
            class="c-custom-action-btn"
            v-ripple
            @click="
              selectedData = item;
              showNilaiDialog = true;
            "
          >
            <img
              :src="`${baseAppPath}/media/window-open-icon.svg`"
              width="10px"
            />
            <span>Lihat Nilai</span>
          </div>
        </template>

        <template v-slot:[`item.peserta`]="{ item }">
          <div
            :key="'btn-peserta-' + item.id"
            class="c-custom-action-btn"
            v-ripple
            @click="
              selectedData = item;
              showPesertaDialog = true;
            "
          >
            <img
              :src="`${baseAppPath}/media/window-open-icon.svg`"
              width="10px"
            />
            <span>Lihat Peserta</span>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="!trashActive" class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-view"
              v-on:click="
                selectedData = item;
                showViewDialog = true;
                getTugas();
              "
            >
              <img :src="`${baseAppPath}/media/view-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-edit"
              @click="
                selectedData = item;
                form = { ...form, ...item, file_location: null };
                openDialog();
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>

          <div v-if="trashActive" class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-view"
              v-on:click="restoreData(item.id)"
            >
              <img :src="`${baseAppPath}/media/reply-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          v-on:click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          v-on:click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="dialog"
        width="600px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-20 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  closeDialog();
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{ selectedData.name ? `Edit ${label}` : `Tambah ${label}` }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div role="alert" class="alert fade alert-danger">
                  <!-- <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div> -->
                </div>

                <b-form-group>
                  <div class="input-title">Nama</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.name.$model"
                    :state="validateState('name')"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Name is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Deskripsi</div>
                  <Editor v-if="dialog" v-model="form.desc"></Editor>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">File</div>

                  <b-form-file
                    ref="file"
                    class="c-file-input form-control form-control-solid h-auto pl-3 pr-7 pb-0"
                    v-model="form.file_location"
                    v-on:change="handleFileUpload()"
                    :state="validateState('file_location')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-file>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    File is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Durasi</div>
                  <div class="d-flex align-items-center">
                    <date-picker
                      v-model="form.start_at"
                      :format="'YYYY-MM-DD'"
                      valueType="format"
                      placeholder="YYYY-MM-DD"
                    ></date-picker>
                    <div class="mx-3 font-weight-bolder">to</div>
                    <date-picker
                      v-model="form.end_at"
                      :format="'YYYY-MM-DD'"
                      valueType="format"
                      placeholder="YYYY-MM-DD"
                    ></date-picker>
                  </div>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    Durasi is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <button
                  ref="kt_submit"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: showViewDialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="showViewDialog"
        width="600px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="() => (showViewDialog = false)"
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <b-row>
                <b-col cols="12" sm="2">
                  <div
                    :style="{ backgroundImage: `url('/media/tugas.svg')` }"
                    style="background-size: contain; background-position: top center;"
                  >
                    <b-aspect aspect="1:1" class="w-100"> </b-aspect>
                  </div>
                </b-col>

                <b-col cols="12" sm="10">
                  <div class="desc mb-2">{{ selectedData.name }}</div>
                  <div class="d-flex align-items-center my-3">
                    <div class="c-tag">
                      {{ selectedData.start_at }} s/d {{ selectedData.end_at }}
                    </div>
                    <div class="c-tag">
                      {{ selectedData.active == 1 ? "Aktif" : "Nonaktif" }}
                    </div>
                  </div>
                </b-col>
              </b-row>

              <div v-html="selectedData.desc" class="modal-desc my-4"></div>

              <div class="mb-4">
                <div class="c-custom-modal-section-title">File Tugas</div>
                <div
                  @click="openModule(selectedData.file_location)"
                  class="c-custom-modal-btn"
                  v-ripple
                >
                  <img
                    :src="`${baseAppPath}/media/window-open-icon.svg`"
                    width="11px"
                  />
                  <span>Buka File</span>
                </div>
              </div>

              <div class="mb-4">
                <div class="c-custom-modal-section-title">Pemberian Nilai</div>
                <div class="c-progress-bar">
                  <div
                    class="c-progress"
                    :style="{
                      flex:
                        dataTugas.filter(el => el.score != null).length /
                        dataTugas.length
                    }"
                  ></div>
                </div>

                <div class="mt-1 mb-2">
                  {{ dataTugas.filter(el => el.score != null).length }} dari
                  {{ dataTugas.length }} nilai tugas terisi
                </div>

                <div
                  class="c-custom-modal-btn"
                  v-ripple
                  @click="showNilaiDialog = true"
                >
                  <img
                    :src="`${baseAppPath}/media/window-open-icon.svg`"
                    width="11px"
                  />
                  <span>Daftar Nilai</span>
                </div>
              </div>

              <div>
                <div class="c-custom-modal-section-title">Tugas Peserta</div>
                <div class="c-progress-bar">
                  <div
                    class="c-progress"
                    :style="{
                      flex:
                        dataTugas.filter(el => el.file_location != null)
                          .length / dataTugas.length
                    }"
                  ></div>
                </div>

                <div class="mt-1 mb-2">
                  {{ dataTugas.filter(el => el.file_location != null).length }}
                  dari {{ dataTugas.length }} peserta pelatihan sudah
                  mengumpulkan tugas
                </div>

                <div
                  class="c-custom-modal-btn"
                  v-ripple
                  @click="showPesertaDialog = true"
                >
                  <img
                    :src="`${baseAppPath}/media/window-open-icon.svg`"
                    width="11px"
                  />
                  <span>Daftar Peserta</span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>

    <v-app
      v-if="showNilaiDialog"
      class="overflow-hidden"
      :style="{ height: showViewDialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="showNilaiDialog"
        width="900px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="() => (showNilaiDialog = false)"
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title mb-6">
                Daftar Penilaian
              </div>

              <TugasNilaiDialog
                v-if="showNilaiDialog"
                :id_tugas="selectedData.id"
              />
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>

    <v-app
      v-if="showPesertaDialog"
      class="overflow-hidden"
      :style="{ height: showViewDialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="showPesertaDialog"
        width="850px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="() => (showPesertaDialog = false)"
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title mb-6">
                Daftar Peserta
              </div>

              <TugasPesertaDialog :id_tugas="selectedData.id" />
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}

.c-tag {
  margin-right: 10px;
  padding: 4px 16px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 40px;
  color: #1e9354;
  background-color: rgba(6, 181, 111, 0.22);
}
</style>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import Moment from "moment";
import DatePicker from "vue2-datepicker";

import Editor from "@/view/components/Editor/Editor.vue";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import TugasNilaiDialog from "./TugasNilaiDialog.vue";
import TugasPesertaDialog from "./TugasPesertaDialog.vue";

export default {
  mixins: [validationMixin],
  props: [
    "label",
    "pelatihanId",
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened"
  ],
  components: {
    Editor,
    DatePicker,
    TugasNilaiDialog,
    TugasPesertaDialog
  },
  data() {
    return {
      search: "",
      columnName: "created_at",
      isDescending: true,
      headers: [
        { text: "Nama", value: "name", width: "130px" },
        { text: "Deskripsi", value: "desc", width: "100px" },
        { text: "File", value: "file_location" },
        { text: "Status", value: "active", width: "100px" },
        { text: "Tanggal Mulai", value: "start_at", width: "130px" },
        { text: "Tanggal Berakhir", value: "end_at", width: "140px" },
        { text: "Hasil/Nilai", value: "hasil", width: "120px" },
        { text: "Peserta", value: "peserta", width: "130px" },
        { text: "Action", value: "actions", sortable: false }
      ],
      data: [],
      selectedData: {},

      dataTugas: [],

      form: {
        pelatihan_id: this.pelatihanId,
        name: null,
        desc: null,
        file_location: null,
        start_at: null,
        end_at: null
      },

      loading: true,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      showViewDialog: false,
      showNilaiDialog: false,
      showPesertaDialog: false,
      trashActive: false
    };
  },
  validations: {
    form: {
      pelatihan_id: {
        required,
        minLength: minLength(1)
      },
      name: {
        required,
        minLength: minLength(1)
      },
      desc: {
        required,
        minLength: minLength(1)
      },
      file_location: {
        // required,
        // minLength: minLength(1)
      },
      start_at: {
        required,
        minLength: minLength(1)
      },
      end_at: {
        required,
        minLength: minLength(1)
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let url = this.trashActive
        ? `api/tugas?trash&tr_pelatihan=${
            this.pelatihanId
          }&token=${JwtService.getToken("elearning")}`
        : `api/tugas?pelatihan=${this.pelatihanId}&token=${JwtService.getToken(
            "elearning"
          )}`;

      ApiService.get(url, "elearning")
        .then(({ data }) => {
          if (data?.data) {
            this.data = data?.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTugas() {
      this.dataTugas = [];

      let url = `api/tugas?tugas=${
        this.selectedData.id
      }&token=${JwtService.getToken("elearning")}`;

      ApiService.get(url, "elearning")
        .then(({ data }) => {
          if (data?.data) {
            this.dataTugas = data?.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var formData = new FormData();
      formData.append("pelatihan_id", this.form.pelatihan_id);
      formData.append("name", this.form.name);
      formData.append("desc", this.form.desc);
      formData.append("start_at", this.form.start_at);
      formData.append("end_at", this.form.end_at);

      if (this.form.file_location) {
        formData.append("file_location", this.form.file_location);
      }

      if (!this?.selectedData?.name) {
        // Create
        ApiService.post(
          `api/tugas?Content-Type=multipart/form-data&token=${JwtService.getToken(
            "elearning"
          )}`,
          formData,
          "elearning"
        )
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: `${this.label} berhasil ditambahkan`
              });
            }

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update

        ApiService.post(
          `api/tugas/${
            this.selectedData.id
          }?_method=PUT&token=${JwtService.getToken("elearning")}`,
          formData,
          "elearning"
        )
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: `Data ${this.label} berhasil diperbarui`
              });
            }
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    deleteData(id) {
      var _this = this;

      this.$swal({
        title: `Lanjutkan menghapus ${this.label}?`,
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(
                `api/tugas/${id}?token=${JwtService.getToken("elearning")}`,
                "elearning"
              )
                .then(() => {
                  _this.getData();
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    restoreData(id) {
      let _this = this;

      this.$swal({
        title: "Lanjutkan memulihkan Tugas?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.post(
                `api/tugas/restore/${id}?token=${JwtService.getToken(
                  "elearning"
                )}`,
                null,
                "elearning"
              )
                .then(() => {
                  var index = _this.data.findIndex(o => o.id === id);
                  if (index !== -1) {
                    let _data = _this.data;
                    _data.splice(index, 1);
                    _this.data = _data;
                  }

                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        pelatihan_id: this.pelatihanId,
        name: null,
        desc: null,
        file_location: null,
        start_at: null,
        end_at: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeStatus(isConfirm) {
      if (!isConfirm) {
        this.selectedData.active = !this.selectedData.active;
      }
      this.cofirmationDialogOpened(false);
    },
    openConfirmationDialog(item) {
      this.selectedData = item;
      this.cofirmationDialogOpened(true);
    },
    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    onPageChanged(page) {
      this.pagination = page;
    },
    handleFileUpload() {
      this.form.file_location = this.$refs.file.files[0];
    },
    openModule(path) {
      window.open(`${process.env.VUE_APP_ELEARNING_URL}${path}`, "_blank");
    }
  }
};
</script>
