<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <div class="d-flex justify-content-end">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="c-datatable">
      <v-data-table
        style="height:100%"
        :headers="headers"
        :items="data"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        height="40vh"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.score`]="{ item }">
          <b-form-input
            type="number"
            class="form-control form-control-solid border-0 font-weight-bolder h-auto py-1 pl-3"
            :style="{
              background: item.edited
                ? '#FFFFFF !important'
                : '#E5EAEE !important'
            }"
            :disabled="!item.edited"
            v-model="item.score"
          ></b-form-input>
        </template>

        <template v-slot:[`item.answer`]="{ item }">
          <div class="text-truncate" style="width: 150px">
            {{ strippedContent(item.answer) }}
          </div>
        </template>

        <template v-slot:[`item.file_location`]="{ item }">
          <div
            :key="'btn-nilai-' + item.id"
            class="c-custom-action-btn"
            v-ripple
            @click="openTugas(item.file_location)"
          >
            <img
              :src="`${baseAppPath}/media/window-open-icon.svg`"
              width="10px"
            />
            <span>Lihat Tugas</span>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <div
              v-if="!item.edited"
              v-ripple
              class="c-datatable-edit"
              @click="
                item.edited = true;
                item.old = { ...item };
                data = [...data];
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>

            <div
              v-if="item.edited"
              v-ripple
              class="c-datatable-cancel"
              @click="
                if (data.findIndex(o => o.id === item.id) !== -1) {
                  data[data.findIndex(o => o.id === item.id)] = {
                    ...item.old,
                    edited: false
                  };
                }

                data = [...data];
              "
            >
              <img :src="`${baseAppPath}/media/cancel-icon.svg`" />
            </div>

            <div
              v-if="item.edited"
              v-ripple
              class="c-datatable-edit"
              @click="
                item.edited = false;
                data = [...data];
                updateData(item);
              "
            >
              <img :src="`${baseAppPath}/media/save-icon.svg`" />
            </div>

            <!-- <div v-ripple class="c-datatable-delete" @click="deleteData(item.id)">
              <img :src="`${baseAppPath}/media/delete-icon.svg`">
            </div> -->
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<style lang="scss">
.c-datatable-check {
  .custom-control-label::before,
  .custom-control-label::after {
    margin-top: -5px;
    width: 1.8rem !important;
    height: 1.8rem !important;
  }
}

.v-table__overflow {
  height: 99.9%;
  max-height: 99.9%;
  overflow-y: auto !important;
}
</style>
<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { BToast } from "bootstrap-vue";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export default {
  mixins: [validationMixin],
  props: ["id_tes"],
  components: {},
  data() {
    return {
      search: "",
      headers: [
        { text: "Nama Peserta", value: "name", width: "160px" },
        { text: "Tanggal Upload", value: "created_at", width: "180px" },
        { text: "Status", value: "status", width: "120px" },
        { text: "Nilai Pretest", value: "pre", width: "120px" },
        { text: "Nilai Posttest", value: "post", width: "120px" }
        // { text: 'Action', value: 'actions', sortable: false },
      ],
      data: [],
      dataModule: [],
      selectedData: {},
      selectedApp: {
        id: null,
        name: null
      },
      selectedRole: {
        id: null,
        name: null
      },

      form: {
        app_id: null,
        module_id: null,
        role_id: null,
        read: 1,
        create: 1,
        update: 1,
        delete: 1
      },

      loading: true,
      page: 1,
      perPage: 9999,
      pagination: {
        pageCount: 1
      },

      errors: []
    };
  },
  validations: {
    form: {
      app_id: {
        required
      },
      module_id: {
        required
      },
      role_id: {
        required
      },
      read: {
        required
      },
      create: {
        required
      },
      update: {
        required
      },
      delete: {
        required
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getData() {
      ApiService.get(
        `api/test?test=${this.id_tes}&token=${JwtService.getToken(
          "elearning"
        )}`,
        "elearning"
      )
        .then(({ data }) => {
          if (data?.data) {
            this.data = data?.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let formData = {
        module_id: this.form.module_id,
        role_id: this.form.role_id,
        read: this.form.read,
        create: this.form.create,
        update: this.form.update,
        delete: this.form.delete
      };

      if (!this?.selectedData?.id) {
        // Create
        ApiService.post(
          `api/module-role?token=${JwtService.getToken("elearning")}`,
          formData
        )
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Role Access berhasil ditambahkan"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        // ApiService.put(`api/module-role/${this?.selectedData?.id}`, formData)
        //   .then((data) => {
        //     if (data?.status == 200 || data?.status == 201){
        //       this.getData();
        //       this.resetForm();
        //       this.closeDialog();
        //       this.$swal.fire({
        //         icon: 'success',
        //         title: 'Berhasil!',
        //         text: 'Data Role Access berhasil diperbarui',
        //       })
        //     }
        //   })
        //   .finally(() => {
        //     submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        //   })
      }
    },
    updateData(item) {
      let formData = {
        score: item.score
      };

      ApiService.put(
        `api/tugasNilai/${item.id}?_method=PUT&token=${JwtService.getToken(
          "elearning"
        )}`,
        formData,
        "elearning"
      ).then(data => {
        if (data?.status == 200 || data?.status == 201) {
          this.getData();

          let bootStrapToaster = new BToast();
          bootStrapToaster.$bvToast.toast("Nilai berhasil diperbarui", {
            title: "Berhasil!",
            toaster: "b-toaster-top-right",
            variant: "success",
            solid: true,
            appendToast: true
          });
        }
      });
    },
    deleteData(id) {
      let _this = this;

      this.$swal({
        title: "Lanjutkan menghapus Role Access?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(
                `api/module-role/${id}?token=${JwtService.getToken(
                  "elearning"
                )}`
              )
                .then(() => {
                  var index = _this.data.findIndex(o => o.id === id);
                  if (index !== -1) {
                    let _data = _this.data;
                    _data.splice(index, 1);
                    _this.data = _data;
                  }

                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        ...this.form,
        read: 1,
        create: 1,
        update: 1,
        delete: 1
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    onPageChanged(page) {
      this.pagination = page;
    },

    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    openTugas(path) {
      window.open(`${process.env.VUE_APP_ELEARNING_URL}${path}`, "_blank");
    }
  },
  computed: {
    ...mapState({
      errors: state => state.account.errors
    })
  }
};
</script>
