var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative z4"},[_c('div',{staticClass:"c-datatable-header d-flex justify-content-between mb-7 pr-16"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('b-icon-search')],1)]},proxy:true}])},[_c('b-form-input',{staticClass:"form-control form-control-solid h-auto py-2 pl-3 pr-7",attrs:{"placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]),_c('div',{staticClass:"c-datatable"},[_c('v-data-table',{staticStyle:{"height":"100%"},attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"items-per-page":_vm.perPage,"page":_vm.page,"loading":_vm.loading,"height":"40vh","fixed-header":"","hide-default-footer":"","dense":""},on:{"pagination":_vm.onPageChanged},scopedSlots:_vm._u([{key:"item.score",fn:function(ref){
var item = ref.item;
return [_c('b-form-input',{staticClass:"form-control form-control-solid border-0 font-weight-bolder h-auto py-1 pl-3",style:({
            background: item.edited
              ? '#FFFFFF !important'
              : '#E5EAEE !important'
          }),attrs:{"type":"number","disabled":!item.edited},model:{value:(item.score),callback:function ($$v) {_vm.$set(item, "score", $$v)},expression:"item.score"}})]}},{key:"item.answer",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"150px"}},[_vm._v(" "+_vm._s(_vm.strippedContent(item.answer))+" ")])]}},{key:"item.file_location",fn:function(ref){
          var item = ref.item;
return [_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],key:'btn-nilai-' + item.id,staticClass:"c-custom-action-btn",on:{"click":function($event){return _vm.openTugas(item.file_location)}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/window-open-icon.svg"),"width":"10px"}}),_c('span',[_vm._v("Lihat Tugas")])])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"c-datatable-actions d-flex"},[(!item.edited)?_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"c-datatable-edit",on:{"click":function($event){item.edited = true;
              item.old = Object.assign({}, item);
              _vm.data = [].concat( _vm.data );}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/edit-icon.svg")}})]):_vm._e(),(item.edited)?_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"c-datatable-cancel",on:{"click":function($event){if (_vm.data.findIndex(function (o) { return o.id === item.id; }) !== -1) {
                _vm.data[_vm.data.findIndex(function (o) { return o.id === item.id; })] = Object.assign({}, item.old,
                  {edited: false});
              }

              _vm.data = [].concat( _vm.data );}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/cancel-icon.svg")}})]):_vm._e(),(item.edited)?_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"c-datatable-edit",on:{"click":function($event){item.edited = false;
              _vm.data = [].concat( _vm.data );
              _vm.updateData(item);}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/save-icon.svg")}})]):_vm._e()])]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }