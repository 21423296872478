<template>
  <div ref="content" class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <div class="d-flex align-items-center">
        <!-- <div v-if="!trashActive" @click="trashActive = true; data = []; loading = true; getData()" class="c-custom-modal-btn py-2 mr-4" v-ripple>
          <img :src="`${baseAppPath}/media/delete-green-icon.svg`" width="10px" class="mb-1">
          <span>Kotak Sampah</span>
        </div>
        
        <div v-if="trashActive" @click="trashActive = false; data = []; loading = true; getData()" class="c-custom-modal-btn py-2 mr-4" v-ripple>
          <span>&#60; Kembali ke Daftar</span>
        </div> -->

        <div class="per-page-wrapper">
          <div>Show:</div>
          <div style="width: 1px; background: #D7D4D3"></div>
          <div>
            <b-dropdown size="sm" variant="link" right no-flip>
              <template v-slot:button-content>
                <div style="margin-left: -15px">{{ perPage }}</div>
              </template>

              <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
              <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
              <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
              <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div>
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="data"
        :sort-by.sync="columnName"
        :sort-desc.sync="isDescending"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.report`]="{ item }">
          <div
            @click="view(item)"
            :key="'btn-peserta-' + item.id"
            class="c-custom-action-btn"
            v-ripple
          >
            <img
              :src="`${baseAppPath}/media/window-open-icon.svg`"
              width="10px"
            />
            <span>Lihat Report</span>
          </div>
        </template>

        <!-- <template v-slot:[`item.actions`]="{ item }">
          <div v-if="!trashActive" class="c-datatable-actions d-flex">
            <div v-ripple class="c-datatable-view" v-on:click="selectedData = item; showViewDialog = true">
              <img :src="`${baseAppPath}/media/view-icon.svg`">
            </div>
            <div v-ripple class="c-datatable-edit" @click="selectedData = item; form = { ...form, ...item, file_location: null, thumbnail: null }; openDialog()">
              <img :src="`${baseAppPath}/media/edit-icon.svg`">
            </div>
            <div v-ripple class="c-datatable-delete" @click="deleteData(item.id)">
              <img :src="`${baseAppPath}/media/delete-icon.svg`">
            </div>
          </div>

          <div v-if="trashActive" class="c-datatable-actions d-flex">
            <div v-ripple class="c-datatable-view" v-on:click="restoreData(item.id)">
              <img :src="`${baseAppPath}/media/reply-icon.svg`">
            </div>
          </div>
        </template> -->
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          v-on:click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          v-on:click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="dialog"
        width="400px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-20 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  closeDialog();
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{ selectedData.name ? "Edit Modul" : "Tambah Modul" }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div role="alert" class="alert fade alert-danger">
                  <!-- <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div> -->
                </div>

                <b-form-group>
                  <div class="input-title">Nama</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.name.$model"
                    :state="validateState('name')"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Name is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Deskripsi</div>
                  <Editor v-if="dialog" v-model="form.desc"></Editor>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">File</div>

                  <b-form-file
                    ref="file"
                    class="c-file-input form-control form-control-solid h-auto pl-3 pr-7 pb-0"
                    v-model="form.file_location"
                    :state="validateState('file_location')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-file>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    File is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Thumbanil</div>

                  <b-form-file
                    ref="file"
                    class="c-file-input form-control form-control-solid h-auto pl-3 pr-7 pb-0"
                    v-model="form.thumbnail"
                    :state="validateState('thumbnail')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-file>
                  <span>*format: png, jpg, jpeg - maksimum 10Mb</span>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    Thumbanil is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <button
                  ref="kt_submit"
                  :disabled="loading"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>

    <v-app
      v-if="showViewDialog"
      class="overflow-hidden"
      :style="{ height: showViewDialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="showViewDialog"
        width="700px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  () => {
                    showViewDialog = false;
                    selectedUser = null;
                    pdfReady = false;
                  }
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div
                v-if="!pdfReady"
                class="d-flex align-items-center justify-content-center"
              >
                <div
                  class="spinner mx-auto"
                  v-bind:class="'spinner-primary'"
                ></div>
              </div>

              <Report
                :ref="report"
                v-if="pdfReady"
                :user="selectedUser"
                :pel="pelatihan"
                :tugas="tugasList"
                :score="score"
                :test="test"
                :testResult="testResult"
                :answers="answers"
              />
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import Moment from "moment";
// import { jsPDF } from "jspdf";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Editor from "@/view/components/Editor/Editor.vue";
import Report from "./Report.vue";

export default {
  mixins: [validationMixin],
  props: [
    "pelatihanId",
    "pelatihan",
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened"
  ],
  components: {
    Editor,
    Report
  },
  data() {
    return {
      search: "",
      columnName: "created_at",
      isDescending: true,
      headers: [
        { text: "Nama Peserta", value: "name", width: "50%" },
        { text: "Report", value: "report", width: "120px" }
        // { text: 'Action', value: 'actions', sortable: false },
      ],
      data: [],
      tugasList: [],
      score: [],
      answers: [],
      test: {},
      testResult: [],
      pdfReady: false,

      selectedData: {},
      selectedUser: null,

      form: {
        pelatihan_id: this.pelatihanId,
        name: null,
        desc: null,
        file_location: null,
        thumbnail: null
      },

      loading: true,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      showViewDialog: false,
      trashActive: false
    };
  },
  validations: {
    form: {
      pelatihan_id: {
        required,
        minLength: minLength(1)
      },
      name: {
        required,
        minLength: minLength(1)
      },
      desc: {
        required,
        minLength: minLength(1)
      },
      file_location: {},
      thumbnail: {}
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let url = this.trashActive
        ? `api/modul?trash&token=${JwtService.getToken("elearning")}`
        : `api/subscribe?pelatihan=${
            this.pelatihanId
          }&token=${JwtService.getToken("elearning")}`;

      ApiService.get(url, "elearning")
        .then(({ data }) => {
          if (data?.data) {
            this.data = data?.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });

      ApiService.get(url, "elearning").then(({ data }) => {
        if (data?.data) {
          this.data = data?.data;
        }
      });

      ApiService.get(
        `api/tugas?pelatihan=${this.pelatihanId}&token=${JwtService.getToken(
          "elearning"
        )}`,
        "elearning"
      ).then(({ data }) => {
        if (data?.data) {
          this.tugasList = data.data;
        }
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var formData = new FormData();
      formData.append("pelatihan_id", this.form.pelatihan_id);
      formData.append("name", this.form.name);
      formData.append("desc", this.form.desc);

      if (this.form.file_location) {
        formData.append("file_location", this.form.file_location);
      }
      if (this.form.file_location) {
        formData.append("thumbnail", this.form.thumbnail);
      }

      if (!this?.selectedData?.name) {
        // Create
        ApiService.post(
          `api/modul?token=${JwtService.getToken("elearning")}`,
          formData,
          "elearning"
        )
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Modul berhasil ditambahkan"
              });
            }

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        ApiService.post(
          `api/modul/${
            this?.selectedData?.id
          }?_method=PUT&token=${JwtService.getToken("elearning")}`,
          formData,
          "elearning"
        )
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Data Modul berhasil diperbarui"
              });
            }

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    deleteData(id) {
      var _this = this;

      this.$swal({
        title: "Lanjutkan menghapus Modul?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(
                `api/modul/${id}?token=${JwtService.getToken("elearning")}`,
                "elearning"
              )
                .then(() => {
                  _this.getData();
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    restoreData(id) {
      let _this = this;

      this.$swal({
        title: "Lanjutkan memulihkan Modul?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.post(
                `api/modul/restore/${id}?token=${JwtService.getToken(
                  "elearning"
                )}`,
                null,
                "elearning"
              )
                .then(() => {
                  var index = _this.data.findIndex(o => o.id === id);
                  if (index !== -1) {
                    let _data = _this.data;
                    _data.splice(index, 1);
                    _this.data = _data;
                  }

                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        pelatihan_id: this.pelatihanId,
        name: null,
        desc: null,
        file_location: null,
        thumbnail: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeStatus(isConfirm) {
      if (!isConfirm) {
        this.selectedData.active = !this.selectedData.active;
      }
      this.cofirmationDialogOpened(false);
    },
    openConfirmationDialog(item) {
      this.selectedData = item;
      this.cofirmationDialogOpened(true);
    },
    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    onPageChanged(page) {
      this.pagination = page;
    },
    openModule() {
      window.open(
        `${process.env.VUE_APP_ELEARNING_URL}/${this.selectedData.file_location}`,
        "_blank"
      );
    },
    async view(user) {
      // var doc = new jsPDF();
      this.showViewDialog = true;
      this.selectedUser = user;
      this.score = [];
      this.answers = [];
      this.test = {};
      this.testResult = [];

      await this.tugasList.forEach(async (el, index) => {
        await ApiService.get(
          `/api/tugas/checkScore/${user.id}/${
            el.id
          }?token=${JwtService.getToken("elearning")}`,
          "elearning"
        ).then(({ data }) => {
          this.score[index] = data?.data?.score;
        });

        await ApiService.get(
          `/api/tugas/checkAnswer/${user.id}/${
            el.id
          }?token=${JwtService.getToken("elearning")}`,
          "elearning"
        ).then(({ data }) => {
          this.answers[index] = data?.data?.created_at;

          // if (index == this.tugasList.length - 1){
          //   setTimeout(() => {
          //     this.pdfReady = true;
          //   }, 2000)
          // }
        });
      });

      await ApiService.get(
        `/api/pelatihan/pdf/${this.pelatihanId}?token=${JwtService.getToken(
          "elearning"
        )}`,
        "elearning"
      )
        .then(async ({ data }) => {
          this.test = data?.test;

          await ApiService.get(
            `/api/test/checkScore/${user.id}/${
              data?.test?.id
            }?token=${JwtService.getToken("elearning")}`,
            "elearning"
          )
            .then(({ data }) => {
              this.testResult = data?.data;
            })
            .finally(() => {
              this.pdfReady = true;
            });
        })
        .finally(() => {
          this.pdfReady = true;
        });
    }
  }
};
</script>
